import React from "react";
import "./style.scss";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const TextSection = () => {
  const {T} = useTranslationCustom();

  return (
    <section id="textSection" className="text-section-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Content-holder">
              <div className="title">
                <h2 className="heading heading-1 font-poppings medium">
                  {T("Filters:Blog_Section_Title")}
                </h2>
              </div>
              <div className="description">
                <p>{T("Filters:Blog_Section_Description").split("|")[0]}</p>
                <br />
                <p>{T("Filters:Blog_Section_Description").split("|")[1]}</p> <br />
                <p>{T("Filters:Blog_Section_Description").split("|")[2]}</p> <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextSection;
