
import { createSlice } from '@reduxjs/toolkit';
// import store from "../index";
// import {setModifiedDate} from "./appTranslationSlice";

const initialState = {
  locale: null,
  currency: null,
  darkMode: true,
  loginUser: {},
  isLogin: false,
};

export const loginconfigSlice = createSlice({
  name: 'loginconfig',
  initialState,
  reducers: {
    resetLoginConfigState: () => initialState,
    changeAppLocale: (state, action) => {
      state.locale = action.payload;
      // store.dispatch(setModifiedDate(''));
    },
    changeAppCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
  },
});

// This will return complete state of this slice.
export const getLoginConfig = (state) => state.loginconfig;

// Action creators are generated for each case reducer function
export const {
  resetLoginConfigState,
  changeAppLocale,
  changeAppCurrency,
  setDarkMode,
  setLoginUser,
  setIsLogin,
} = loginconfigSlice.actions;

export default loginconfigSlice.reducer;
