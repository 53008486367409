import React, {memo, useEffect, useRef, useState} from "react";
import RoomCard from "./RoomCard";
import Slider from "react-slick";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {getSearchFilters, setSelectedHotelDetail, setPaginatedRoomTypes} from "../../../redux/slices/searchFilterSlice";
import {getBooking, setHotelRoom} from "../../../redux/slices/bookingSlice";
import PropTypes from "prop-types";
import {
    getPager, getQueryParamValue,
    getTravelerCountFromRoomUrl,
    paginateData,
    replaceQueryParam,
    updateFlightPrice
} from "../../../helpers/common";
import BedIcon from "../../../assets/images/icons/icon-bed.svg";
import LoaderJson from '../../../assets/images/lottieViews/loader.json';
import Lottie from "lottie-react";
import {useNavigate} from "react-router-dom";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const RoomCardSlider = ({roomsFetched}) => {
    let setTimeOutId = null;
    const slider = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {T} = useTranslationCustom();
    const searchfilters = useSelector(getSearchFilters);
    const {paginatedRoomTypes} = searchfilters;
    const {room, flight} = useSelector(getBooking);
    const [page, setPage] = useState(1);
    const [tempPrice, setTempPrice] = useState(null);
    const [shouldStop, setShouldStop] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    useEffect(() => {
        if (room) {
            const index = paginatedRoomTypes?.findIndex(
                (f) => f?.roomKey === getQueryParamValue('roomKey')
            );
            if (index !== -1 && slider) {
                setCurrentSlideIndex(index);
                slider?.current?.slickGoTo(index, true);
            } else if (index === -1 && slider) {
                setCurrentSlideIndex(0);
                slider?.current?.slickGoTo(0, true);
            }
        }
    }, [slider?.current]);

    useEffect(() => {
        if (roomsFetched) {
            doPaginationOnSlider(page);
        }
    }, [page, roomsFetched]);

    useEffect(() => {
        if (!paginatedRoomTypes?.length && roomsFetched) {
            setPage(1);
            doPaginationOnSlider(1);
            slider?.current?.slickGoTo(0, true);
        }
    }, [paginatedRoomTypes]);

    useEffect(() => {
        if (roomsFetched && paginatedRoomTypes?.length) {
            let _rooms = [...paginatedRoomTypes];

            // For each loop will replace new offer with previous ones.
            _rooms.forEach((_room, _index) => {
                const index = searchfilters?.selectedHotelDetail?.findIndex(
                    f => f?.roomKey === _room?.roomKey,
                );
                if (index !== -1) {
                    _rooms[_index] = searchfilters?.selectedHotelDetail[index];
                }
            });

            dispatch(setPaginatedRoomTypes(_rooms));
        }

    }, [searchfilters.selectedHotelDetail]);

    useEffect(() => {
        if (tempPrice) {
            setTimeOutId = setTimeout(() => setTempPrice(null), 4000);
        }

        return () => {
            clearTimeout(setTimeOutId);
        };
    }, [tempPrice]);

    useEffect(() => {
        if (roomsFetched) {
            const selectedRoom = paginatedRoomTypes[currentSlideIndex];

            const priceToAdd = Number(selectedRoom?.rateDifference);

            const updatedRoomsArray = searchfilters?.selectedHotelDetail?.map(
                room => {
                    return {
                        ...room,
                        rateDifference: (Number(room?.rateDifference) - priceToAdd).toFixed(
                            2,
                        ),
                    };
                },
            );

            if (priceToAdd !== 0) {
                if (setTimeOutId) {
                    setTempPrice(null);
                    clearTimeout(setTimeOutId);
                }
                setTempPrice(priceToAdd.toString());
            }

            dispatch(setHotelRoom(selectedRoom));

            dispatch(setSelectedHotelDetail(updatedRoomsArray));

            const totalTravelers = getTravelerCountFromRoomUrl(paginatedRoomTypes[0]?.links?.webDetails?.href);

            updateFlightPrice(
                totalTravelers,
                priceToAdd,
                flight,
                dispatch,
            );
        }
    }, [currentSlideIndex]);

    const doPaginationOnSlider = _page => {
        let _rooms = [];
        if (searchfilters?.selectedHotelDetail?.length > 1) {
            const pager = getPager(searchfilters?.selectedHotelDetail?.length, _page, 7);

            if (_page > pager.totalPages) {
                return;
            }

            _rooms = paginateData(
                searchfilters?.selectedHotelDetail,
                pager.pageSize,
                pager.currentPage,
            );

            if (
                paginatedRoomTypes?.length <=
                searchfilters?.selectedHotelDetail?.length
            ) {
                _rooms = [...paginatedRoomTypes].concat(_rooms);
                let _uniqueRooms = [];
                _rooms.forEach(_f => {
                    if (!_uniqueRooms.find(_uf => _uf.roomKey === _f.roomKey)) {
                        _uniqueRooms.push(_f);
                    }
                });
                _rooms = _uniqueRooms;
            }
        } else {
            _rooms = searchfilters?.selectedHotelDetail;
        }

        dispatch(setPaginatedRoomTypes(_rooms))
    };

    function SampleNextArrow({onClick}) {
        return (
            <span
                className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
                onClick={() => {
                    if (currentSlideIndex === searchfilters?.selectedHotelDetail?.length - 1) {
                        return;
                    }
                    onClick();
                }}
            ></span>
        );
    }

    function SamplePrevArrow({onClick}) {
        return (
            <>
                {currentSlideIndex !== 0 && <span
                    className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
                    onClick={() => {
                        if (currentSlideIndex === 0) {
                            return;
                        }
                        onClick();
                    }}
                ></span>}
                {/* <div className="custom-pagination">
                    {paginatedRoomTypes?.findIndex((r) => r?.roomKey === getQueryParamValue('roomKey')) + 1 + ' / '} {searchfilters?.selectedHotelDetail?.length || 0}
                </div> */}
            </>
        );
    }

    SampleNextArrow.propTypes = {
        onClick: PropTypes.func,
    };

    SamplePrevArrow.propTypes = {
        onClick: PropTypes.func,
    };

    const settings = {
        slidesToShow: 1,
        infinite: false,
        speed: 300,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        arrows: true,
        centerMode: true,
        centerPadding: "0%",
        variableWidth: true,
        swipe: !shouldStop,
        draggable: !shouldStop,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
        ],
    };

    const getIndexForSingleRoom = () => {
        const index = searchfilters?.selectedHotelDetail?.findIndex(
            (f) => f?.roomKey === room?.roomKey
        );
        return index === -1 ? 0 : index;
    };

    const getRoomCountText = () => {
        return `${searchfilters?.selectedHotelDetail?.length > 1 ?
            `${paginatedRoomTypes?.findIndex((r) => r?.roomKey === getQueryParamValue('roomKey')) + 1 + ' / '} ${searchfilters?.selectedHotelDetail?.length || 0} ${T("Common:Rooms")}` :
            `${searchfilters?.selectedHotelDetail?.length || 0} ${T("Common:Room")}`} ${T("Common:Found")}`
    }

    return (
        <>
            <div className="title-cards-outer">
                <img src={BedIcon} alt="plane"/>
                <h5>
                    {T("Packages_Detail:Choose_Your_Room")}
                </h5>
            </div>

            <div className="room-information">
                <div className="title">
                    <h5>
                        <span>
                            {!roomsFetched &&
                                <Lottie animationData={LoaderJson} loop={true} className='photo_animation'/>}
                            {!roomsFetched ? T("Packages_Detail:Searching_Rooms") : getRoomCountText()}
                        </span>
                    </h5>
                </div>
                <div className="room-card-slider-wrapper">
                    {searchfilters?.selectedHotelDetail?.length > 1 ? (
                            <Slider
                                ref={slider}
                                {...settings}
                                beforeChange={(oldSlide, currentSlide) => {
                                    if (currentSlide !== -1) {
                                        console.log("Slider changes", {currentSlide});
                                        setCurrentSlideIndex(currentSlide);
                                        const params = replaceQueryParam('roomKey', paginatedRoomTypes[currentSlide]?.roomKey);
                                        navigate(`?${params}`, {replace: true});
                                        if (
                                            paginatedRoomTypes?.length <
                                            searchfilters.selectedHotelDetail?.length &&
                                            currentSlide === paginatedRoomTypes?.length - 1
                                        ) {
                                            return;
                                        }
                                        if (
                                            currentSlide === paginatedRoomTypes?.length - 1 ||
                                            currentSlide === paginatedRoomTypes?.length - 2
                                        ) {
                                            console.log(
                                                `current page is ${page} and next page will be ${page + 1}`,
                                            );
                                            setPage(page + 1);
                                        }
                                    }
                                }}
                            >
                                {paginatedRoomTypes?.map((room, index) => {
                                    return <RoomCard
                                        room={room}
                                        key={index}
                                        roomIndex={index}
                                        tempPrice={tempPrice}
                                        currentSlideIndex={currentSlideIndex}
                                        index={index}
                                        setShouldStop={(value) => {
                                            setShouldStop(value);
                                        }}
                                    />;
                                })}
                            </Slider>
                        ) :
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {searchfilters?.selectedHotelDetail?.length === 1 && (
                                <div className="roomcard-outer" style={{width: "320px", margin: "auto"}}>
                                    <RoomCard
                                        room={searchfilters?.selectedHotelDetail[0]}
                                        index={0}
                                        roomIndex={getIndexForSingleRoom()}
                                        setShouldStop={() => null}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </>
    )
        ;
};

RoomCardSlider.propTypes = {
    roomsFetched: PropTypes.bool.isRequired,
};

export default memo(RoomCardSlider);
