import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Col, Row } from "react-bootstrap";
import "./style.scss";
import { useSelector } from "react-redux";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import { NavLink, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHeart, faUser } from "@fortawesome/pro-solid-svg-icons";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const UserTab = () => {
  const { T } = useTranslationCustom();
  const loginconfig = useSelector(getLoginConfig);

  return (
    <Tab.Container>
      <Row>
        <Col lg={3}>
          <Nav variant="pills" className="flex-column tab-controls">
            <Nav.Item>
              <NavLink to={"/me/favorites"}>
                <FontAwesomeIcon icon={faHeart} /> {T("Home:Home_My_Favourites")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={"/me/settings"}>
                <FontAwesomeIcon icon={faBell} /> {T("Home:Home_Settings")}
              </NavLink>
            </Nav.Item>
            {loginconfig.isLogin && (
              <Nav.Item>
                <NavLink to={"/me/account"}>
                  <FontAwesomeIcon icon={faUser} /> {T("Buttons:Account")}
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Col>
        <Col lg={9}>
          <Outlet />
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default UserTab;
