import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getBooking } from "../../../redux/slices/bookingSlice";
import {
    getSearchFilters, setPaginatedRoomFlights,
    setRoomFlights,
} from "../../../redux/slices/searchFilterSlice";
import "./style.scss";
import AppConfig from "../../../helpers/config";
import PropTypes from "prop-types";
import {
    getPager,
    getQueryParamValue,
    paginateData,
    replaceQueryParam,
    updateFlightPrice
} from "../../../helpers/common";
import FlightCard from "./FlightCard";
import PlaneIcon from "../../../assets/images/icons/airplane.svg";
import Lottie from "lottie-react";
import LoaderJson from "../../../assets/images/lottieViews/loader.json";
import { useNavigate } from "react-router-dom";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const FlightCardSlider = ({ flightsFetched }) => {
    let setTimeOutId = null;
    const slider = useRef(null);
    const navigate = useNavigate();
    const { T } = useTranslationCustom();
    const dispatch = useDispatch();
    const bookingState = useSelector(getBooking);
    const searchfilters = useSelector(getSearchFilters);
    const { paginatedRoomFlights } = searchfilters;
    const [page, setPage] = useState(1);
    const [shouldStop, setShouldStop] = useState(false);
    const [tempPrice, setTempPrice] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    useEffect(() => {
        if (bookingState?.flight) {
            const index = paginatedRoomFlights?.findIndex(
                (f) => f.offerId === getQueryParamValue('offerId')
            );
            if (index !== -1 && slider) {
                setCurrentSlideIndex(index);
                slider?.current?.slickGoTo(index, true);
            } else if (index === -1 && slider) {
                setCurrentSlideIndex(0);
                slider?.current?.slickGoTo(0, true);
            }
        }
    }, [slider?.current]);

    useEffect(() => {
        if (flightsFetched) {
            doPaginationOnSlider(page);
        }
    }, [page, flightsFetched]);

    useEffect(() => {
        if (!paginatedRoomFlights?.length && flightsFetched) {
            setPage(1);
            doPaginationOnSlider(1);
            slider?.current?.slickGoTo(0, true);
        }
    }, [paginatedRoomFlights]);

    useEffect(() => {
        if (flightsFetched && paginatedRoomFlights?.length) {
            let _flights = [...paginatedRoomFlights];

            // For each loop will replace new offer with previous ones.
            _flights.forEach((_flight, _index) => {
                const index = searchfilters.roomFlights?.findIndex(
                    f => f?.offerId === _flight?.offerId,
                );
                // found the flight
                if (index !== -1) {
                    _flights[_index] = searchfilters.roomFlights[index];
                }
            });

            dispatch(setPaginatedRoomFlights(_flights));
        }

    }, [searchfilters.roomFlights]);

    useEffect(() => {
        if (tempPrice) {
            setTimeOutId = setTimeout(() => setTempPrice(null), 4000);
        }

        return () => {
            clearTimeout(setTimeOutId);
        };
    }, [tempPrice]);

    useEffect(() => {
        if (flightsFetched) {
            const selectedFlight = paginatedRoomFlights[currentSlideIndex];
            const priceToAddForSurcharges = Number(
                selectedFlight?.price?.averageCostPerPerson?.value,
            );

            const updatedFlightsArray = searchfilters.roomFlights?.map(flight => {
                const surcharges = (
                    Number(flight?.price?.averageCostPerPerson?.value) -
                    priceToAddForSurcharges
                ).toFixed(0);

                return {
                    ...flight,
                    surcharges:
                        selectedFlight?.offerId === flight.offerId ? '0' : surcharges,
                    isSelected: selectedFlight?.offerId === flight.offerId,
                };
            });

            if (Number(selectedFlight?.surcharges) !== 0) {
                if (setTimeOutId) {
                    setTempPrice(null);
                    clearTimeout(setTimeOutId);
                }
                setTempPrice(selectedFlight?.surcharges);
            }

            // console.log(updatedFlightsArray.map(f => f.surcharges));

            dispatch(setRoomFlights(updatedFlightsArray));

            const totalTravelers = bookingState.hotel?.totalTravelers;
            const previousSelectedFlight = bookingState.flight;

            updateFlightPrice(
                totalTravelers,
                Number(selectedFlight?.surcharges),
                previousSelectedFlight,
                dispatch,
                selectedFlight,
            );
        }
    }, [currentSlideIndex]);

    const doPaginationOnSlider = _page => {
        let _flights = [];
        if (searchfilters?.roomFlights?.length > 1) {
            const pager = getPager(searchfilters?.roomFlights?.length, _page, 7);

            if (_page > pager.totalPages) {
                return;
            }

            _flights = paginateData(
                searchfilters.roomFlights,
                pager.pageSize,
                pager.currentPage,
            );

            if (
                paginatedRoomFlights?.length <=
                searchfilters?.roomFlights?.length
            ) {
                _flights = [...paginatedRoomFlights].concat(_flights);
                let _uniqueFlights = [];
                _flights.forEach(_f => {
                    if (!_uniqueFlights.find(_uf => _uf.offerId === _f.offerId)) {
                        _uniqueFlights.push(_f);
                    }
                });
                _flights = _uniqueFlights;
            }
        } else {
            _flights = searchfilters?.roomFlights;
        }

        dispatch(setPaginatedRoomFlights(_flights));
    };

    function SampleNextArrow({ onClick }) {
        return (
            <span
                className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
                onClick={() => {
                    if (currentSlideIndex === searchfilters?.roomFlights?.length - 1) {
                        return;
                    }
                    // Sometimes on navigating back to this page the slider stuck so this check make the pagination smooth.
                    if (!onClick && searchfilters?.roomFlights?.length > paginatedRoomFlights?.length) {
                        setPage(page + 1);
                    } else {
                        onClick();
                    }
                }}
            ></span>
        );
    }

    function SamplePrevArrow({ onClick }) {
        return (
            <>
                {currentSlideIndex !== 0 && <span
                    className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
                    onClick={() => {
                        if (currentSlideIndex === 0) {
                            return;
                        }
                        onClick();
                    }}
                ></span>}
            </>
        );
    }

    SampleNextArrow.propTypes = {
        onClick: PropTypes.func,
    };

    SamplePrevArrow.propTypes = {
        onClick: PropTypes.func,
    };

    const settings = {
        slidesToShow: 1,
        infinite: false,
        speed: 300,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        arrows: true,
        centerMode: true,
        centerPadding: "0%",
        variableWidth: true,
        swipe: !shouldStop,
        draggable: !shouldStop,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0",
                },
            },
        ],
    };

    const _flight = searchfilters?.roomFlights[0];
    // Departure ticket data
    const _departureSegmentId = _flight?.segmentIds[0];
    const _departureFlightSegment = _flight?.segments?.filter(
        (f) => f.segmentId === _departureSegmentId
    )[0];
    const _departureStart = _departureFlightSegment?.legs[0];
    const _lastDepartureLegIndex = _departureFlightSegment?.legs?.length - 1;
    const _departureEnd = _departureFlightSegment?.legs[_lastDepartureLegIndex];
    // End of data

    // Arrival ticket data
    const _arrivalSegmentId =
        _flight?.segmentIds[_flight?.segmentIds?.length - 1];
    const _arrivalFlightSegment = _flight?.segments?.filter(
        (f) => f.segmentId === _arrivalSegmentId
    )[0];
    const _arrivalStart = _arrivalFlightSegment?.legs[0];
    const _lastArrivalLegIndex = _arrivalFlightSegment?.legs?.length - 1;
    const _arrivalEnd = _arrivalFlightSegment?.legs[_lastArrivalLegIndex]; // Last occuring return segment and flight.
    // End of data

    const getFlightCountText = () => {
        return `${searchfilters?.roomFlights?.length > 1 ? `${paginatedRoomFlights?.findIndex((f) => f?.offerId === getQueryParamValue('offerId')) + 1 + ' / '} ${searchfilters?.roomFlights?.length || 0} ${T("Common:Flights_Found")}` : `${searchfilters?.roomFlights?.length || 0} ${T("Common:Flight_Found")}`}`
    }

    const processedFlights = paginatedRoomFlights?.map((flight) => {

        const departureSegmentId = flight?.segmentIds[0];
        const departureFlightSegment = flight?.segments?.filter(
            f => f.segmentId === departureSegmentId,
        )[0];
        const departureStart = departureFlightSegment?.legs[0];
        const lastDepartureLegIndex = departureFlightSegment?.legs?.length - 1;
        const departureEnd = departureFlightSegment?.legs[lastDepartureLegIndex];
        // End of data

        // Arrival ticket data
        const arrivalSegmentId = flight?.segmentIds[flight?.segmentIds?.length - 1];
        const arrivalFlightSegment = flight?.segments?.filter(
            f => f.segmentId === arrivalSegmentId,
        )[0];
        const arrivalStart = arrivalFlightSegment?.legs[0];
        const lastArrivalLegIndex = arrivalFlightSegment?.legs?.length - 1;
        const arrivalEnd = arrivalFlightSegment?.legs[lastArrivalLegIndex]; // Last occuring return segment and flight.
        // End of data

        // const departureSegment = flight?.segments?.[0];
        // const lastDepartureLeg = departureSegment?.legs?.[departureSegment?.legs?.length - 1];
        // const arrivalSegment = flight?.segments?.[flight?.segments?.length - 1];
        // const lastArrivalLeg = arrivalSegment?.legs?.[arrivalSegment?.legs?.length - 1];

        return {
            flight,
            isSelected: bookingState?.flight?.offerId === flight?.offerId,
            departure: {
                icon: AppConfig.getAirlineImageURL(
                    departureStart?.marketingAirlineCode,
                ),
                cabinClass: departureStart?.cabinClass,
                departureAirport: departureStart?.departureAirport,
                departureDateTime: departureStart?.departureDateTime,
                estimateTime: departureFlightSegment?.flightDuration,
                arrivalAirport: departureEnd?.arrivalAirport,
                arrivalDateTime: departureEnd?.arrivalDateTime,
                numberOfStops: departureFlightSegment?.totalStops,
                departureStart: departureStart,
            },
            arrival: {
                icon: AppConfig.getAirlineImageURL(
                    arrivalStart?.marketingAirlineCode,
                ),
                cabinClass: arrivalStart?.cabinClass,
                departureAirport: arrivalStart?.departureAirport,
                departureDateTime: arrivalStart?.departureDateTime,
                estimateTime: arrivalFlightSegment?.flightDuration,
                arrivalAirport: arrivalEnd?.arrivalAirport,
                arrivalDateTime: arrivalEnd?.arrivalDateTime,
                numberOfStops: arrivalFlightSegment?.totalStops,
                arrivalStart: arrivalStart,
            },
        };
    });

    return (
        <>
            <div className="title-cards-outer">
                <img src={PlaneIcon} alt="plane" />
                <div className="text">
                    <h5>
                        {T("Packages_Detail:Choose_Your_Flight")}
                    </h5>
                </div>
            </div>
            <div className="flight-information">
                <div className="title">
                    <div className="text">
                        <h5>
                            <span>
                                {!flightsFetched &&
                                    <Lottie animationData={LoaderJson} loop={true} className='photo_animation' />}
                                {!flightsFetched ? T("Packages_Detail:Searching_Flights") : getFlightCountText()}
                            </span>
                        </h5>
                    </div>
                </div>
                <div className="flight-slider">
                    {searchfilters?.roomFlights?.length > 1 ? (
                        <Slider ref={slider} {...settings}
                            beforeChange={(oldSlide, currentSlide) => {
                                if (currentSlide !== -1) {
                                    console.log("Slider changes", { currentSlide });
                                    setCurrentSlideIndex(currentSlide);
                                    const params = replaceQueryParam('offerId', paginatedRoomFlights[currentSlide]?.offerId);
                                    navigate(`?${params}`, { replace: true });
                                    if (
                                        paginatedRoomFlights?.length <
                                        searchfilters.roomFlights?.length &&
                                        currentSlide === paginatedRoomFlights?.length - 1
                                    ) {
                                        return;
                                    }
                                    if (
                                        currentSlide === paginatedRoomFlights?.length - 1 ||
                                        currentSlide === paginatedRoomFlights?.length - 2
                                    ) {
                                        console.log(
                                            `current page is ${page} and next page will be ${page + 1}`,
                                        );
                                        setPage(page + 1);
                                    }
                                }
                            }}
                        >
                            {processedFlights?.map(({ flight, isSelected, departure, arrival }, index) => (
                                <div className="flightslide-outer" key={index}>
                                    <div className={isSelected ? "each-slide selected" : "each-slide"}>
                                        <FlightCard
                                            flight={flight}
                                            index={index}
                                            tempPrice={tempPrice}
                                            currentSlideIndex={currentSlideIndex}
                                            departure={{ type: "departure", ...departure }}
                                            arrival={{ type: "arrival", ...arrival }}
                                            setShouldStop={(value) => setShouldStop(value)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) :
                        <div className="flightslide-outer" style={{ width: "320px", margin: "auto" }}>
                            <div className={"each-slide selected"}>
                                <FlightCard
                                    flight={searchfilters.roomFlights[0]}
                                    index={0}
                                    departure={{
                                        type: "departure",
                                        icon: AppConfig.getAirlineImageURL(
                                            _departureStart?.marketingAirlineCode
                                        ),
                                        cabinClass: _departureStart?.cabinClass,
                                        departureAirport: _departureStart?.departureAirport,
                                        departureDateTime: _departureStart?.departureDateTime,
                                        estimateTime: _departureFlightSegment?.flightDuration,
                                        arrivalAirport: _departureEnd?.arrivalAirport,
                                        arrivalDateTime: _departureEnd?.arrivalDateTime,
                                        numberOfStops: _departureFlightSegment?.totalStops,
                                        departureStart: _departureStart
                                    }}
                                    arrival={{
                                        type: "arrival",
                                        icon: AppConfig.getAirlineImageURL(
                                            _arrivalStart?.marketingAirlineCode
                                        ),
                                        cabinClass: _arrivalStart?.cabinClass,
                                        departureAirport: _arrivalStart?.departureAirport,
                                        departureDateTime: _arrivalStart?.departureDateTime,
                                        estimateTime: _arrivalFlightSegment?.flightDuration,
                                        arrivalAirport: _arrivalEnd?.arrivalAirport,
                                        arrivalDateTime: _arrivalEnd?.arrivalDateTime,
                                        numberOfStops: _arrivalFlightSegment?.totalStops,
                                        arrivalStart: _arrivalStart
                                    }}
                                    setShouldStop={() => null}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

FlightCardSlider.propTypes = {
    handleSelectedClass: PropTypes.func,
    flightsFetched: PropTypes.bool,
};

export default memo(FlightCardSlider);
