import React, { useEffect, useState, useCallback } from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import ImageViewer from "react-simple-image-viewer";
import "./style.scss";
import { useSelector } from "react-redux";
import { getSearchFilters } from "../../../redux/slices/searchFilterSlice";

const ImageGallery = () => {
    const url = window.location.href;
    const parts = url.split("/");
    const type = parts[parts.length - 1];
    const key = parts[parts.length - 2];
    const { selectedHotel, selectedHotelDetail } = useSelector(getSearchFilters);
    const [media, setMedia] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if (type === 'room') {
            const _media = selectedHotelDetail.filter(f => f.roomKey === key)[0]?.media;
            setMedia(_media);
        } else {
            setMedia(selectedHotel?.media);
        }
    }, []);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            <Gallery>
                <div id="gallery" className="container">
                    {media?.map((_image, index) => {
                        return (
                            <Item key={index} original={_image} thumbnail={_image} width="1024" height="768">
                                {({ ref }) => <img ref={ref} onClick={() => openImageViewer(index)} src={_image?.url} alt={'gallery-pic'} />}
                            </Item>
                        )
                    })}
                </div>
            </Gallery>
            {isViewerOpen && (
                <div className={"image-viewer-container"}>
                    <ImageViewer
                        src={media.map(m => m?.url)}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                </div>
            )}
        </>
    )
};

export default ImageGallery;
