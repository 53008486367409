import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getSearchFilters } from "../../redux/slices/searchFilterSlice";
import { GetAirportsService } from "../../services/AirportsService";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const DepartureFromModal = (props) => {
  const {T} = useTranslationCustom();
  const loginConfig = useSelector(getLoginConfig);
  const searchfilters = useSelector(getSearchFilters);
  const [departure, setDeparture] = useState("");
  const [departures, setDepartures] = useState([]);

  useEffect(() => {
    if (props.show) {
      handleSearchingAPI(departure);
    }
  }, [departure, props.show]);

  useEffect(() => {
    setDepartures(searchfilters.departureFrom);
  }, [props.show]);

  const handleSearchingAPI = (text) => {
    try {
      setDeparture(text);
      GetAirportsService(text, loginConfig?.locale?.code)
        .then((_response) => {
          let previousSelectedAirports = searchfilters.departureFrom?.filter(
            (f) => f.isSelected
          );
          const newAirports = _response?.airports?.map((a) => ({
            ...a,
            isSelected: false,
          }));
          newAirports?.forEach((airport) => {
            if (
              previousSelectedAirports?.findIndex(
                (f) => f.code === airport.code
              ) === -1
            ) {
              previousSelectedAirports?.push(airport);
            }
          });
          setDepartures(previousSelectedAirports);
        })
        .catch((error) => {
          console.log({ error });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const selectionHandler = (departure) => {
    const _departures = [...departures];
    const index = _departures?.findIndex((f) => f.code === departure.code);
    if (
      !departure.isSelected &&
      _departures.filter((f) => f.isSelected).length === 3
    ) {
      return toast.info("You can only select three airports");
    } else if (index !== -1) {
      _departures[index] = {
        ...departure,
        isSelected: !_departures[index].isSelected,
      };
      setDepartures(_departures);
    }
  };

  return (
    <Modal {...props} size="sm" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="departure-modal">
          <h4 className="fs-22 medium">{T("Filters:Departure_Modal_Heading")}</h4>
          <p
            className="btn-apply"
            onClick={() => {
              props.onApplyButtonHandler(
                departures.filter((f) => f.isSelected)
              );
              props.onHide();
            }}
          >
            {T("Buttons:Apply")}
          </p>
        </div>
        <div className="search-form">
          <div className="form-group">
            <input
              id="departure"
              name="departure"
              placeholder={T("Filters:Departure_Modal_Placeholder")}
              value={departure}
              onChange={(e) => handleSearchingAPI(e.target.value)}
            />
          </div>
        </div>
        <ul className="suggestion-list">
          {departures &&
            departures?.map((_departure, index) => {
              return (
                <li
                  key={index}
                  style={
                    _departure.isSelected
                      ? { backgroundColor: "var(--green)" }
                      : {}
                  }
                  dangerouslySetInnerHTML={{
                    __html: `${_departure.code} ${_departure.name}`,
                  }}
                  onClick={() => selectionHandler(_departure)}
                />
              );
            })}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

DepartureFromModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onApplyButtonHandler: PropTypes.func,
};

export default DepartureFromModal;
