import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSelector} from "react-redux";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import "./style.scss";
import PropTypes from "prop-types";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const RoomContent = ({room}) => {
    const {T} = useTranslationCustom();
    const searchfilters = useSelector(getSearchFilters);
    const selectedHotel = searchfilters?.selectedHotel;

    return (
        <div className="content-holder">
            <h2 className="heading heading-1 medium">{room?.description}</h2>
            <h5 className="heading heading-5 medium gray-text">
                {`${room?.bedTypeOptions ? room?.bedTypeOptions[0]?.description + ',' : ''} ${room?.smokingOption || T('Packages_Detail_No_Smoking')}`}
            </h5>
            <div className="more-info">
                <div className="star">
                    {[1, 2, 3, 4, 5].map((item, index) => {
                        if (item <= Number(searchfilters.selectedHotel?.starRating))
                            return <FontAwesomeIcon key={index} icon={faStar} className="filled"/>
                        else return <FontAwesomeIcon key={index} icon={faStar} className="blank"/>
                    })}
                    <span>{selectedHotel?.guestRating} {selectedHotel?.guestReviewCount && `(${selectedHotel?.guestReviewCount})`}</span>
                </div>
            </div>

        </div>
    );
};

RoomContent.propTypes = {
    room: PropTypes.object.isRequired,
}

export default RoomContent;
