import { faShareSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const TabTitle = ({title}) => {
  const { T } = useTranslationCustom();

  return (
    <div className="tab-title">
      <h2 className="white-text fs-38 medium">{title}</h2>
      {title === "My Favriotes" && (
        <a className="action">
          <FontAwesomeIcon icon={faShareSquare} /> {T("Buttons:Share")}
        </a>
      )}
    </div>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default TabTitle;
