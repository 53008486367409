import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import React, {useEffect, useState} from "react";

import {GoogleOAuthProvider} from '@react-oauth/google';

import {useDispatch, useSelector} from "react-redux";
import AppRoutes from "./routes";
import Loader from "./components/Loader/Loader";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    changeAppCurrency,
    changeAppLocale,
    getLoginConfig,
    resetLoginConfigState
} from "./redux/slices/loginconfigSlice";
import {
    getSearchFilters, resetSearchFiltersState,
    setTravelDate,
} from "./redux/slices/searchFilterSlice";
import TransactionWrapper from "./container/TransactionWrapper";
import {isMobile, isTablet} from 'react-device-detect';
import ScrollLock from "./components/ScrollLock";
import DownloadApp from "./components/DownloadApp";
import AppConfig from "./helpers/config";
import {resetAppConfigState} from "./redux/slices/appconfigSlice";
import {resetBookingSliceState} from "./redux/slices/bookingSlice";
import TranslationProvider from "./i18n/TranslationProvider";

function App() {
    const dispatch = useDispatch();
    const appconfig = useSelector((state) => state.appconfig);
    const loginConfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const {status} = useSelector(state => state.appTranslations);
    const [showDownloadSection, setShowDownloadSection] = useState(true);

    const resetAllSlices = () => (dispatch) => {
        dispatch(resetAppConfigState());
        dispatch(resetBookingSliceState());
        dispatch(resetLoginConfigState());
        dispatch(resetSearchFiltersState());
    };

    useEffect(() => {
        const savedVersion = localStorage.getItem('gt_app_version');
        const browserLang = navigator.language;
        const browserLocale = browserLang.split('-')[0];
        const browserCountry = browserLang.split('-')[1] || ''; // Handle case where there is no region part
        // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Fresh app start logic
        if (!loginConfig?.locale?.code) {
            console.log('Locale not found so setting locale.');
            if (browserLocale === 'nl') {
                dispatch(changeAppLocale(AppConfig.appLanguages[0])); // NL
                dispatch(changeAppCurrency(AppConfig.currencies[0])); // Euro
                console.log(
                    `NL Case: Choosing ${AppConfig.appLanguages[0].name} language and ${AppConfig.currencies[0]?.name} currency`,
                );
            } else if (browserLocale === 'en' && browserCountry === 'US') {
                dispatch(changeAppLocale(AppConfig.appLanguages[1])); // EN
                dispatch(changeAppCurrency(AppConfig.currencies[1])); // Dollar
                console.log(
                    `EN Case: Choosing ${AppConfig.appLanguages[1].name} language and ${AppConfig.currencies[1]?.name} currency`,
                );
            } else {
                dispatch(changeAppLocale(AppConfig.appLanguages[1])); // EN
                dispatch(changeAppCurrency(AppConfig.currencies[0])); // Euro
                console.log(
                    `Fallback Case: Choosing ${AppConfig.appLanguages[1].name} language and ${AppConfig.currencies[0]?.name} currency`,
                );
            }
        }

        if (savedVersion !== AppConfig.webVersion) {
            // Version mismatch, reset the Redux store
            dispatch(resetAllSlices());
            window.location.href = '/';
            // Update the stored version to the current version
            localStorage.setItem('gt_app_version', AppConfig.webVersion);
        }

        return () => {
            if (
                searchfilters?.travelDate?.startDate &&
                new Date(searchfilters?.travelDate?.startDate) <= new Date()
            ) {
                dispatch(setTravelDate({startDate: "", endDate: ""}));
            }
        }
    }, [dispatch, loginConfig?.locale?.code]);

    useEffect(() => {
        document.body.id = loginConfig.darkMode ? "darkMode" : "";
    }, [loginConfig.darkMode]);

    return (
        <GoogleOAuthProvider clientId="752153235622-du75254s7dkvo8l472g3ib552umv4pmt.apps.googleusercontent.com">
            <TranslationProvider>
                {status === 'succeeded' ? (
                        <TransactionWrapper>
                            <AppRoutes/>
                            {showDownloadSection && (isMobile || isTablet) &&
                                <DownloadApp setShowDownloadSection={setShowDownloadSection}/>}
                            {appconfig?.loadingScreen &&
                                <ScrollLock isOpen={appconfig?.loadingScreen}>
                                    <Loader/>
                                </ScrollLock>
                            }
                            <ToastContainer
                                position="bottom-right"
                                autoClose={4000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </TransactionWrapper>
                    ) :
                    <Loader/>
                }
            </TranslationProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
