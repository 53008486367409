import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GetAppLocales} from '../services/AppService';
import {setActivityIndicator} from "../redux/slices/appconfigSlice";
import {fetchTranslations, setAppLocales} from "../redux/slices/appTranslationSlice";
import {changeAppLocale} from "../redux/slices/loginconfigSlice";
import PropTypes from "prop-types";

const TranslationProvider = ({children}) => {
    const dispatch = useDispatch();
    const {lastModified} = useSelector(state => state.appTranslations);
    const loginConfig = useSelector(state => state.loginconfig);

    const fetchLocalesTranslations = useCallback((languageCode) => {
        dispatch(setActivityIndicator(true));
        GetAppLocales()
            .then(response => {
                const locales = response?.data;
                dispatch(setActivityIndicator(false));
                dispatch(setAppLocales(locales));

                console.log({locales});

                console.log({languageCode});

                if (languageCode) {
                    dispatch(fetchTranslations({
                        locale: languageCode,
                        lastModified: languageCode !== loginConfig?.locale?.code ? '' : lastModified,
                    }));
                } else {
                    const defaultLanguage = locales?.find(f => f.short === 'en');
                    dispatch(fetchTranslations({
                        locale: defaultLanguage?.code,
                        lastModified,
                    }));
                    dispatch(changeAppLocale(defaultLanguage));
                }
            })
            .catch(e => {
                dispatch(setActivityIndicator(false));
                console.log({e});
            });
    }, []);

    useEffect(() => {
        console.log('Language updated to ', loginConfig?.locale?.code)
        fetchLocalesTranslations(loginConfig?.locale?.code);
    }, [loginConfig?.locale?.code]);

    return children;
};

TranslationProvider.propTypes = {
    children: PropTypes.any
}


export default TranslationProvider;
