import React from "react";
import Modal from "react-bootstrap/Modal";

// images
import PlaneIcon from "../../assets/images/icons/green-plane-up.svg";
import PlaneAltIcon from "../../assets/images/icons/green-plane-landing.svg";
import GrayPlaneIcon from "../../assets/images/icons/grey-plane-up.svg";
import GrayPlaneAltIcon from "../../assets/images/icons/grey-plane-down.svg";
import PlaneFlat from "../../assets/images/icons/plane-flat.svg";
import TicketIcon from "../../assets/images/icons/ticket-blue.svg";
import PlaneBlueIcon from "../../assets/images/icons/plane-up.svg";
import SeatIcon from "../../assets/images/icons/seat-plane.svg";
import PropTypes from "prop-types";
import {
  getActualFlightDetails,
  getEstimateTimeFormat,
  getTotalStops,
} from "./flightHelpers";
import moment from "moment";
import AppConfig from "../../helpers/config";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

function FlightCardModal(props) {
  const {T} = useTranslationCustom();
  const { flight } = props;
  // Departure ticket data first
  const departureSegmentId = flight?.segmentIds[0];
  const departureFlightSegment = flight?.segments?.filter(
    (f) => f.segmentId === departureSegmentId
  )[0];
  // Arrival ticket data last
  const arrivalSegmentId = flight?.segmentIds[flight?.segmentIds?.length - 1];
  const arrivalFlightSegment = flight?.segments?.filter(
    (f) => f.segmentId === arrivalSegmentId
  )[0];

  return (
    <Modal
      {...props}
      dialogClassName="flights-modal-dialogue"
      size="lg"
      aria-labelledby="flight-card-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="flight-card-modal">{T("Packages_Detail:Flight_Detail_Text")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlightDetailCardInfo
          className={"departure"}
          departure={true}
          flightSegment={departureFlightSegment}
          T={T}
        />
        <FlightDetailCardInfo
          className={"return"}
          departure={false}
          flightSegment={arrivalFlightSegment}
          T={T}
        />
      </Modal.Body>
    </Modal>
  );
}

const FlightDetailCardInfo = ({ className, departure, flightSegment, T }) => {
  return (
    <div className={className}>
      <h5 className="card-title">{departure ? T("Packages_Detail:Departure") : T("Packages_Detail:Return") }</h5>
      <div className="flight-box-main">
        <ActualFlight
          data={getActualFlightDetails({ departure, flightSegment })}
          flightSegment={flightSegment}
          actualFlight={true}
          departure={departure}
        />

        {flightSegment?.legs?.length > 1 &&
          flightSegment?.legs?.map((_flight, index) => (
            <ActualFlight
              key={index}
              data={{
                startFlight: flightSegment?.legs[index],
                endFlight: flightSegment?.legs[index],
              }}
              actualFlight={false}
              departure={departure}
            />
          ))}
      </div>
    </div>
  );
};

FlightDetailCardInfo.propTypes = {
  className: PropTypes.string,
  flightSegment: PropTypes.object,
  departure: PropTypes.bool,
  T: PropTypes.any
};

const ActualFlight = (props) => {
  const { data, flightSegment, actualFlight } = props;
  const { startFlight, endFlight } = data;
  const { T } = useTranslationCustom();
  // console.log(departure);
  const FlightServices = () => {
    return (
      <div className="flight-details-outer">
        <div className="flight-details-number">
          {startFlight?.flightNumber && (
            <div>
              <img src={TicketIcon} alt="ticket" /> {startFlight?.flightNumber}
            </div>
          )}
          {startFlight?.equipmentName && (
            <div>
              <img src={PlaneBlueIcon} alt="ticket" />{" "}
              {startFlight?.equipmentName}
            </div>
          )}
          {startFlight?.cabinClass && (
            <div>
              <img src={SeatIcon} alt="ticket" /> {startFlight?.cabinClass}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flight-card-box">
      <div
        className={`d-flex align-items-center justify-content-between flight-box ${
          actualFlight ? "flight-summary" : ""
        }`}
      >
        <div className="text-start">
          <img src={actualFlight ? PlaneIcon : GrayPlaneIcon} alt="plane" />
          <p>{startFlight?.departureAirport?.city}</p>
          <h5>{startFlight?.departureAirport?.code}</h5>
          <p>
            {moment
              .parseZone(startFlight?.departureDateTime)
              .format("HH:mm ddd, MMM DD")}
          </p>
        </div>
        <div className="middle-text">
          <div>
            <img
            className="card-logo-img"
              src={AppConfig.getAirlineImageURL(
                startFlight?.marketingAirlineCode
              )}
              alt={"card-logo"}
            />
          </div>
          {actualFlight && <img src={PlaneFlat} alt="plane" />}
          <span>
            {actualFlight
              ? `${getEstimateTimeFormat(flightSegment?.flightDuration)} |
                            ${getTotalStops(flightSegment?.totalStops, T)}`
              : `${getEstimateTimeFormat(startFlight?.flightDuration)}`}
          </span>
        </div>
        <div className="text-end">
          <img
            src={actualFlight ? PlaneAltIcon : GrayPlaneAltIcon}
            alt="plane"
          />
          <p>{endFlight?.arrivalAirport?.city}</p>
          <h5>{endFlight?.arrivalAirport?.code}</h5>
          <p>
            {moment
              .parseZone(endFlight?.arrivalDateTime)
              .format("HH:mm ddd, MMM DD")}
          </p>
        </div>
      </div>

      <div className="seprator-mask"></div>

      {((actualFlight && flightSegment?.totalStops === "0") ||
        !actualFlight) && <FlightServices />}
    </div>
  );
};

ActualFlight.propTypes = {
  data: PropTypes.object,
  flightSegment: PropTypes.object,
  departure: PropTypes.bool,
  actualFlight: PropTypes.bool,
};

FlightCardModal.propTypes = {
  flight: PropTypes.object,
};

export default FlightCardModal;
