import React, {useState} from "react";
import TabTitle from "./TabsTitle";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    getSearchFilters,
    setDepartureFrom,
    setTravelers,
} from "../../../redux/slices/searchFilterSlice";
import {
    setActivityIndicator,
} from "../../../redux/slices/appconfigSlice";
import {
    changeAppCurrency,
    changeAppLocale,
    getLoginConfig,
} from "../../../redux/slices/loginconfigSlice";
import {UpdateProfileService} from "../../../services/UpdateProfileService";
import DepartureFromModal from "../../Modals/DepartureFromModal";
import {toast} from "react-toastify";
import AppConfig from "../../../helpers/config";
import Dropdown from "react-bootstrap/Dropdown";
import Travelers from "../QuickFilters/Travelers";
import {getAppTranslations} from "../../../redux/slices/appTranslationSlice";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const SettingsTabContent = () => {
    const {T} = useTranslationCustom();
    const dispatch = useDispatch();
    const searchfilters = useSelector(getSearchFilters);
    const loginconfig = useSelector(getLoginConfig);
    const appTranslations = useSelector(getAppTranslations);
    const [form, setForm] = useState({
        locale: loginconfig?.locale,
        departures: searchfilters.departureFrom,
    });
    const [passwordInfo, setPasswordInfo] = useState({
        CurrentPwd: "",
        NewPwd: "",
    });
    const [modals, setModals] = useState({departure: false});
    const [showTraveler, setShowTraveler] = useState(false);

    const getTravelers = () => {
        const adults = searchfilters.travelers.reduce(
            (n, e) => (e.adult ? n + e.adult : n),
            0
        );
        const infants = searchfilters.travelers.reduce(
            (n, e) => (e.infants ? n + e.infants : n),
            0
        );
        const childs = searchfilters.travelers.reduce(
            (n, e) => (e.child ? n + e.child : n),
            0
        );
        return `${adults} ${adults > 1 ? T("Common:Adults") : T("Common:Adult")} , ${childs} ${childs > 1 ? T("Common:ChildS") : T("Common:Child")}, ${infants} ${infants > 1 ? T("Common:Infants") : T("Common:Infant")}, ${searchfilters.travelers.length} ${searchfilters.travelers.length > 1 ? T("Common:Rooms") : T("Common:Room")}`;
    };

    const handleOnUpdate = async (e) => {
        e.preventDefault();
        try {
            dispatch(changeAppLocale(form.locale));
            dispatch(setDepartureFrom(form.departures));
            if (loginconfig.isLogin) {
                const paramsPassword = {
                    Guid: loginconfig.loginUser.guid,
                    UpdateContent: "Password",
                    ...passwordInfo,
                };

                if (
                    paramsPassword.NewPwd.match(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,}$"
                    ) === null
                ) {
                    toast(T("Profile:Password_Info"));
                    return;
                }
                dispatch(setActivityIndicator(true));
                await UpdateProfileService(paramsPassword);
                toast(T("Profile:Password_Updated"));
                setPasswordInfo({CurrentPwd: "", NewPwd: ""});
                dispatch(setActivityIndicator(false));
            }
        } catch (error) {
            dispatch(setActivityIndicator(false));
        }
    };

    const onReset = (e) => {
        e.preventDefault();
        setPasswordInfo({
            CurrentPwd: "",
            NewPwd: "",
        });
        setForm({
            locale: loginconfig?.locale,
        });
        dispatch(
            setTravelers([
                {
                    child: 0,
                    adult: 1,
                    infants: 0,
                },
            ])
        );
    };

    const getDepartureValues = () => {
        return form.departures?.map((f) => f?.code)?.join(", ");
    };

    return (
        <>
            <TabTitle title={T("Profile:Settings")}></TabTitle>
            <div className="tabs-inner-content">
                <div className="account-form-holder">
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="green-text fs-16 font-noto">{T("Profile:Settings")}</h6>
                            </div>
                            {loginconfig.isLogin && (
                                <>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-style" htmlFor="c-pass">
                                                {T("Profile:Your_Current_Password")}
                                            </label>
                                            <input
                                                type="password"
                                                name="c-pass"
                                                id="c-pass"
                                                placeholder={T("Profile:Enter_Current_Password")}
                                                className="input-style"
                                                autoComplete="none"
                                                value={passwordInfo?.CurrentPwd}
                                                onChange={(e) =>
                                                    setPasswordInfo({
                                                        ...passwordInfo,
                                                        CurrentPwd: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="label-style" htmlFor="n-pass">
                                                {T("Profile:Your_New_Password")}
                                            </label>
                                            <input
                                                type="password"
                                                name="n-passs"
                                                placeholder={T("Profile:Your_New_Password")}
                                                className="input-style"
                                                autoComplete="none"
                                                value={passwordInfo?.NewPwd}
                                                onChange={(e) =>
                                                    setPasswordInfo({
                                                        ...passwordInfo,
                                                        NewPwd: e.target.value,
                                                    })
                                                }
                                            />
                                            <p>{T("Profile:Password_Info")}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="label-style" htmlFor="lang">
                                        {T("Profile:Language").toUpperCase()}
                                    </label>
                                    <Dropdown>
                                        <Dropdown.Toggle>{loginconfig.locale?.short?.toUpperCase()}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {appTranslations?.locales.map((language, index) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={index}
                                                        onClick={() =>
                                                            dispatch(changeAppLocale(language))
                                                        }
                                                        active={language?.id === loginconfig.locale.id}
                                                    >
                                                        {language?.country}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="label-style" htmlFor="curr">
                                        {T("Profile:Currency").toUpperCase()}
                                    </label>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            {loginconfig?.currency?.name}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {AppConfig.currencies.map((currency, index) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={index}
                                                        onClick={() => dispatch(changeAppCurrency(currency))}
                                                        active={currency?.code === loginconfig?.currency?.code}
                                                    >
                                                        {`${currency?.symbol} ${currency?.name?.toUpperCase()}`}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="traveler form-group">
                                    <label className="label-style" htmlFor="travellers">
                                        {T("Profile:Travelers")}
                                    </label>
                                    <Dropdown
                                        show={showTraveler}
                                        onToggle={(show) => {
                                            setShowTraveler(show);
                                        }}
                                    >
                                        <Dropdown.Toggle>{getTravelers()}</Dropdown.Toggle>
                                        <Travelers setShowTraveler={setShowTraveler}/>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="label-style" htmlFor="departure">
                                        {T("Filters:Departure_From").toUpperCase()}
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        id="departure"
                                        name="departure"
                                        placeholder="Amsterdam"
                                        className="input-style"
                                        value={getDepartureValues()}
                                    />
                                    <a
                                        className="add-more-departures"
                                        onClick={() => setModals({departure: !modals.departure})}
                                    >
                                        {T("Profile:More_Departures")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group ">
                                    <button className="update" onClick={handleOnUpdate}>
                                        {T("Buttons:Update_Profile")}
                                    </button>
                                    <button className="clear" onClick={onReset}>
                                        {T("Buttons:Clear_All")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DepartureFromModal
                show={modals.departure}
                onHide={() => setModals({departure: false})}
                onApplyButtonHandler={(values) => {
                    setForm({...form, departures: values});
                }}
            />
        </>
    );
};

export default SettingsTabContent;
