import React from 'react';
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const NotFound = () => {
    const {T} = useTranslationCustom();

    return (
        <div>{T("Common:Page_Not_Found")}</div>
    )
}

export default NotFound;
