import React, {useEffect, useRef, useState} from "react";
import RoomCard from "./RoomCard";
import Slider from "react-slick";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import {getBooking} from "../../../redux/slices/bookingSlice";
import PropTypes from "prop-types";
import {getTravelerCountFromRoomUrl, updateFlightPrice} from "../../../helpers/common";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const RoomsSlider = ({setRoom, selectedRoom, oldFlight, priceChangeHandler}) => {
    let setTimeOutId = null;
    const slider = useRef();
    const dispatch = useDispatch();
    const {T} = useTranslationCustom();
    const [rooms, setRooms] = useState([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [tempPrice, setTempPrice] = useState(null);
    const [justArrived, setJustArrived] = useState(true);
    const searchfilters = useSelector(getSearchFilters);
    const booking = useSelector(getBooking);

    useEffect(() => {
        setRooms(searchfilters?.selectedHotelDetail);
        const index = searchfilters?.selectedHotelDetail?.findIndex(
            (f) => f.roomKey === booking?.room?.roomKey
        );
        if (index !== currentSlideIndex && slider) {
            setTimeout(() => {
                setCurrentSlideIndex(index);
                slider?.current?.slickGoTo(index, true);
                setRoom(searchfilters?.selectedHotelDetail[index]);
            }, 1500);
        }
        setTimeout(() => {
            if (justArrived) {
                setJustArrived(false);
            }
        }, index !== currentSlideIndex ? 2500 : 500);
    }, [searchfilters?.selectedHotelDetail]);

    useEffect(() => {
        if (tempPrice) {
            setTimeOutId = setTimeout(() => setTempPrice(null), 4000);
        }

        return () => {
            clearTimeout(setTimeOutId);
        };
    }, [tempPrice]);

    useEffect(() => {
        if (rooms?.length) {
            const selectedRoom = rooms[currentSlideIndex];
            const priceToAdd = Number(selectedRoom?.rateDifference);

            if (priceToAdd !== 0) {
                if (setTimeOutId) {
                    setTempPrice(null);
                    clearTimeout(setTimeOutId);
                }
                setTempPrice(priceToAdd.toString());
            }

            const updatedRoomsArray = rooms?.map(
                room => {
                    return {
                        ...room,
                        rateDifference: (Number(room?.rateDifference) - priceToAdd).toFixed(
                            2,
                        ),
                    };
                },
            );

            setRooms(updatedRoomsArray);

            const totalTravelers = getTravelerCountFromRoomUrl(rooms[0]?.links?.webDetails?.href);

            let flight = updateFlightPrice(
                totalTravelers,
                priceToAdd,
                oldFlight,
                dispatch,
                null,
                true
            );
            priceChangeHandler(flight);
        }
    }, [currentSlideIndex]);

    function SampleNextArrow({onClick}) {
        return (
            <span
                className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
                onClick={onClick}
            ></span>
        );
    }

    function SamplePrevArrow({onClick}) {
        return (
            <>
                <span
                    className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
                    onClick={onClick}
                ></span>
                <div className="custom-pagination">
                    {rooms?.findIndex((f) => f.roomKey === selectedRoom?.roomKey) + 1 + ' / '} {rooms?.length || 0}
                </div>
            </>
        );
    }

    SampleNextArrow.propTypes = {
        onClick: PropTypes.func,
    };

    SamplePrevArrow.propTypes = {
        onClick: PropTypes.func,
    };

    const settings = {
        slidesToShow: 1,
        infinite: true,
        speed: 300,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        arrows: true,
        centerMode: true,
        centerPadding: "35%",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "30%",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "25%",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15%",
                },
            },
        ],
    };

    const getIndexForSingleRoom = () => {
        const index = rooms?.findIndex(
            (f) => f?.roomKey === selectedRoom?.roomKey
        );
        return index === -1 ? 0 : index;
    };

    return (
        <div className="room-information">
            <div className="title">
                <h5>
                    {T("Packages_Detail:Choose_Your_Room")}
                    <span>{rooms?.length || 0} {T("Common:Rooms")}{" "}{T("Common:Found")} </span>
                </h5>
            </div>
            <div className="room-card-slider-wrapper">
                {rooms?.length > 1 ? (
                        <Slider
                            afterChange={(currentSlide) => {
                                if (!justArrived) {
                                    setCurrentSlideIndex(currentSlide);
                                    setRoom(rooms[currentSlide]);
                                }
                            }}
                            ref={slider}
                            {...settings}
                        >
                            {rooms?.map((room, index) => {
                                return <RoomCard
                                    room={room}
                                    key={index}
                                    tempPrice={tempPrice}
                                    currentSlideIndex={currentSlideIndex}
                                    index={index}
                                />;
                            })}
                        </Slider>
                    ) :
                    <div style={{display: "flex", flexDirection: "row"}}>
                        {rooms?.length === 1 && (
                            <div className="roomcard-outer" style={{width: "30%"}}>
                                <RoomCard
                                    room={rooms[0]}
                                    index={0}
                                    roomIndex={getIndexForSingleRoom()}
                                />
                            </div>
                        )}
                    </div>}
            </div>
        </div>
    );
};

RoomsSlider.propTypes = {
    setRoom: PropTypes.func,
    selectedRoom: PropTypes.object,
    oldFlight: PropTypes.object,
    priceChangeHandler: PropTypes.func,
};

export default RoomsSlider;
