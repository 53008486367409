import React from "react";
import TopImage from "../../../assets/images/genius_bestemming.svg";
import FloatingImage from "../../../assets/images/top-section-floating-image.svg";
import AeroplaneBed from "../../../assets/images/icons/aroplane-bed.svg";
import QuickFilter from "../QuickFilters/index";
import "./style.scss";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const TopSection = () => {
    const {T} = useTranslationCustom();

    return (
        <section id="top-section" className="top-section-wrapper">
            <div className="container">
                <div className="content-wrapper d-flex">
                    <div className="image-holder">
                        <img src={TopImage} alt={'top-img'}/>
                    </div>
                    <img src={FloatingImage} className="floating-image" alt={'floating-image'}/>
                    <div className="content-hodler">
                        <h6 className="subtitle heading fs-17 white-text font-noto">
                            <img src={AeroplaneBed} alt={'aeroplane'}/>
                            {T("Home:Home_Hotel_Incl_Flight").toUpperCase()}
                        </h6>
                        <h1 className="heaidng fs-70 white-text bold font-noto">
                            {T("Home:Home_Explore_Globe")}
                        </h1>
                        <p className="white-text fs-24 font-noto ">
                            {T("Home:Home_Find_And_Book_Text")}
                        </p>
                    </div>
                </div>
                <div className="home-quick">
                    <QuickFilter/>
                </div>
            </div>
        </section>
    );
};

export default TopSection;
