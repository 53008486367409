import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {GetAppTranslations} from '../../services/AppService';
// import {translations} from "../../i18n/locales/en/new";

// Async thunk to fetch translations from the API
export const fetchTranslations = createAsyncThunk(
  'appTranslations/fetchTranslations',
  async ({locale, lastModified}, {rejectWithValue}) => {
    try {
      console.log({locale, lastModified});
      return await GetAppTranslations(locale, lastModified);
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          message: err.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({message: err.message});
    }
  },
);

const initialState = {
  locales: [],
  translations: null,
  status: 'idle',
  error: null,
  lastModified: '',
};

export const appTranslationSlice = createSlice({
  name: 'appTranslations',
  initialState,
  reducers: {
    setAppLocales: (state, action) => {
      state.status = 'idle';
      state.locales = action.payload;
    },
    setModifiedDate: (state, action) => {
      state.lastModified = action.payload;
    },
    setTranslationStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTranslations.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchTranslations.fulfilled, (state, action) => {
        state.translations = action.payload?.data?.data;
        // state.translations = translations;
        state.lastModified = action.payload?.data?.last_modified;
        state.status = 'succeeded';
      })
      .addCase(fetchTranslations.rejected, (state, action) => {
        // state.translations = translations;
        const statusCode = action.payload?.status;
        if (statusCode === 304 || statusCode === 404) {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
        }
        state.error = action.error.message;
      });
  },
});

export const getAppTranslations = (state) => state.appTranslations;

export const {setAppLocales,setModifiedDate, setTranslationStatus} = appTranslationSlice.actions;

export default appTranslationSlice.reducer;
