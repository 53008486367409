import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../../assets/images/icons/icon-list.svg";
import GridIcon from "../../../assets/images/icons/grid-icon.svg";
import {
  getAppConfig,
  setShowRectangular,
} from "../../../redux/slices/appconfigSlice";
import "./style.scss";

const Filter = () => {
  const dispatch = useDispatch();
  const appconfig = useSelector(getAppConfig);

  return (
    <div id="filter">
      <div className="filter-wrapper desktop-only">
        <div className="right-sider-filter">
          <div className="list-grid-toggler">
            <a
              onClick={() =>
                dispatch(setShowRectangular(!appconfig.showRectangular))
              }
            >
              <img
                src={appconfig.showRectangular ? GridIcon : ListIcon}
                alt={"list-icon"}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="filter-mob-wrapper mobile-only">
        <div className="list-grid-toggler">
          <a
            onClick={() =>
              dispatch(setShowRectangular(!appconfig.showRectangular))
            }
          >
            <img
              src={appconfig.showRectangular ? GridIcon : ListIcon}
              alt={"list-icon"}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Filter;
