import React, {useEffect, useState} from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";
import axios from "axios";
import AppConfig from "../../helpers/config";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const TermsConditions = () => {
    const {T} = useTranslationCustom();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginconfig = useSelector(getLoginConfig);
    const [htmlContent, setHtmlContent] = useState('');

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    useEffect(() => {
        scrollToTop();
        getStaticContent(loginconfig?.locale);
    }, [loginconfig?.locale]);

    const getStaticContent = async (language) => {
        try {
            dispatch(setActivityIndicator(true));
            const value = await localStorage.getItem("auth_token");
            const headers = {
                Authorization: `Bearer ${value}`,
                "Content-Type": "application/json",
                'X-Locale': language?.short?.toLowerCase()
            };
            const content = await axios.get(`${AppConfig.baseCMSApiURL}/pages?type=terms_and_conditions`, {headers});
            setHtmlContent(content?.data?.data[0]);
            dispatch(setActivityIndicator(false));
        } catch (e) {
            console.log({e}, 'static page error');
            dispatch(setActivityIndicator(false));
        }
    }

    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <div className="container">
                    <AppHeader/>
                    <main className="body-wrapper">
                        <div className="left-action-back-btn">
                            <a onClick={() => navigate(-1)}>{T("Buttons:Back")}</a>
                        </div>
                        <h2 className="text-center">{htmlContent?.title}</h2>
                        <div
                            dangerouslySetInnerHTML={{__html: htmlContent?.content}}
                        />
                    </main>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default TermsConditions;
