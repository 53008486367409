import React from "react";
import MobileImage from "../../../assets/images/mobile-App-Image.png";
import Android from "../../../assets/images/Google-appstore.png";
import Apple from "../../../assets/images/Apple-appstore.png";
import "./style.scss";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const MobileApp = () => {
  const { T } = useTranslationCustom();

  return (
    <section id="mobileApp" className="mobile-app-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="title">
              <h2 className="heading heading-1 font-poppings medium">
                {T("Common:Find_Trips_From_Seat")}
              </h2>
              <p className="fs-24 font-poppings gray-text">
                {T("Common:Download_App")}
              </p>
              <div className="app-actions">
                <a
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.appelit.geniustravel"
                  target={"_blank"}
                >
                  <img src={Android} alt={"android"} />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/us/app/genius/id1661100442"
                  target={"_blank"}
                >
                  <img src={Apple} alt={"apple"} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="image-holder">
              <img src={MobileImage} alt={"mobile-image"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileApp;
