import "./style.scss";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faLocationDot} from "@fortawesome/pro-solid-svg-icons";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";
import {DateRangePicker} from "react-date-range";
import moment from "moment/moment";
import {enUS, nl} from "date-fns/locale";
import {
    getSearchFilters, setSelectedFavoriteHotel,
    setTravelDate,
    setTravelers,
} from "../../redux/slices/searchFilterSlice";
import React, {useEffect, useRef, useState} from "react";
import {format} from "date-fns";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../utils/getWindowDimensions";
import {getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {
    getAppConfig,
    setActivityIndicator,
} from "../../redux/slices/appconfigSlice";
import {
    getCheckInOutDatesFromFlight,
    getTravelersData,
    navigateToDetailPage,
} from "../../helpers/common";
import {
    GetBestTravelDates,
    GetSearchOnlyForHotel,
} from "../../services/AppService";
import {toast} from "react-toastify";
import DepartureFromModal from "../Modals/DepartureFromModal";
import Travelers from "../sections/QuickFilters/Travelers";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const FavoriteAccordion = ({offer}) => {
    const {T} = useTranslationCustom();
    const calendarRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const searchfilters = useSelector(getSearchFilters);
    const loginConfig = useSelector(getLoginConfig);
    const appConfig = useSelector(getAppConfig);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTraveler, setShowTraveler] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isReset, setIsReset] = useState(
        !searchfilters.travelDate.startDate && !searchfilters.travelDate.endDate
    );
    const [endDate, setEndDate] = useState("");
    const [bestDates, setBestDates] = useState([]);
    const _flight = offer?.objHotel?.newPackagedOffer?.flightOffer || offer?.flightOffer;
    const [dateRangeState, setDateRangeState] = useState([
        {
            key: "selection",
            startDate: new Date(offer?.departureDate || getCheckInOutDatesFromFlight(_flight)?.checkInDate),
            endDate: new Date(offer?.returnDate || getCheckInOutDatesFromFlight(_flight)?.checkOutDate),
        },
    ]);
    const [departureFrom, setLocalDepartureFrom] = useState([]);
    const [modals, setModals] = useState({
        destination: false,
        departure: false,
    });

    const [hotelPreviousFilters] = useState({
        travelers: searchfilters?.travelers,
        departureFrom: searchfilters?.departureFrom,
        dateRangeState: [
            {
                key: "selection",
                startDate: new Date(offer?.departureDate || getCheckInOutDatesFromFlight(_flight)?.checkInDate),
                endDate: new Date(offer?.returnDate || getCheckInOutDatesFromFlight(_flight)?.checkOutDate),
            },
        ],
    });

    useEffect(() => {
        if (!offer?.hotelId && !offer?.hotelImg) {
            // Normal Hotel
            // console.log({offer})
            const flightData = offer?.flightOffer || offer?.objHotel?.newPackagedOffer?.flightOffer; // Selected flight from redux
            const departureSegmentId = flightData?.segmentIds[0];
            const departureFlightSegment = flightData?.segments?.filter(
                f => f.segmentId === departureSegmentId,
            )[0];
            const departureFlight = departureFlightSegment?.legs[0];
            const _departureFrom = departureFlight?.departureAirport?.code;
            // console.log({_departureFrom})
            setLocalDepartureFrom([_departureFrom]);
        } else {
            setLocalDepartureFrom([offer?.departure]);
        }

    }, [])

    useEffect(() => {
        width < 767 ? setIsMobile(true) : setIsMobile(false);
    }, [width]);

    useEffect(() => {
        if (showCalendar) {
            getBestDatesFromServer(calendarRef?.current?.props?.ranges[0]?.endDate);
        }
    }, [showCalendar, endDate]);

    useEffect(() => {
        if (dateRangeState.length) {
            setEndDate(dateRangeState[0].endDate);
            if (
                dateRangeState[0]?.startDate?.getTime() !==
                dateRangeState[0]?.endDate?.getTime()
            ) {
                setIsReset(false);
            }
        }
    }, [dateRangeState]);

    // This will get best dates from server.
    const getBestDatesFromServer = (date) => {
        const month = new Date(date).getMonth();

        const persona =
            (appConfig.packageTypes.length && appConfig.packageTypes[0].personas) ||
            "Couples";

        try {
            Promise.all([
                GetBestTravelDates({month: month + 1, persona}),
                GetBestTravelDates({month: month + 2, persona}),
            ])
                .then((stack) => {
                    let _bestDates = [];
                    stack.forEach((response, index) => {
                        if (response?.bestTravelDates?.length) {
                            let dates = response?.bestTravelDates.map((bestDate) => {
                                const year = new Date(
                                    searchfilters.travelDate.endDate
                                ).getFullYear();
                                return {
                                    day: bestDate.dayOfMonth,
                                    date: new Date(
                                        moment(
                                            `${year}-${month + index + 1}-${bestDate.dayOfMonth}`
                                        ).format("YYYY-MM-DD")
                                    ),
                                };
                            });
                            _bestDates = [..._bestDates, ...dates];
                        }
                    });
                    setBestDates(_bestDates);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (e) {
            console.log({e});
        }
    };

    const dateCheckIfLiesUnder = (from, to, check) => {
        var fDate, lDate, cDate;
        fDate = Date.parse(from);
        lDate = Date.parse(to);
        cDate = Date.parse(check);
        return cDate <= lDate && cDate >= fDate;
    };

    const handleRangeChange = (item) => {
        const cSDate = item?.selection?.startDate;
        const pSSDate = dateRangeState[0]?.startDate;
        const pSEDate = dateRangeState[0]?.endDate;

        const currentDuration = moment.duration(
            moment(pSEDate).diff(moment(pSSDate))
        );
        const startDateDuration = moment.duration(
            moment(cSDate).diff(moment(pSSDate))
        );

        const endDateDuration = moment.duration(
            moment(cSDate).diff(moment(pSEDate))
        );

        const cDays = currentDuration.asDays();
        const sDays = startDateDuration.asDays();
        const eDays = endDateDuration.asDays();

        // Selected date is larger than current range. Right side click
        if (sDays >= 28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        } else if (sDays < 28 && sDays > eDays) {
            if (dateCheckIfLiesUnder(pSSDate, pSEDate, cSDate)) {
                if (sDays <= 0 || cDays / 2 - sDays >= 0) {
                    setDateRangeState([
                        {
                            key: "selection",
                            startDate: cSDate,
                            endDate: pSEDate,
                        },
                    ]);
                } else if (eDays >= 0 || cDays / 2 + eDays >= 0) {
                    setDateRangeState([
                        {
                            key: "selection",
                            startDate: pSSDate,
                            endDate: cSDate,
                        },
                    ]);
                }
                return;
            }

            setDateRangeState([
                {
                    key: "selection",
                    startDate: pSSDate,
                    endDate: cSDate,
                },
            ]);
        }

        // Selected date is less than current range. left side click
        if (sDays < 0 && eDays > -28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: pSEDate,
                },
            ]);
        } else if (sDays < 0 && eDays < -28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        }

        if (
            dateRangeState[0]?.startDate?.getTime() ===
            dateRangeState[0]?.endDate?.getTime() &&
            isReset
        ) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        }
    };

    const customDayContent = (day) => {
        let extraDot = null;
        const getDate = (date) => moment(date).format("YYYY-MM-DD").toString();
        if (
            bestDates.filter((bD) => getDate(day) === getDate(bD.date)).length > 0
        ) {
            extraDot = <div className="dateDot"/>;
        }
        return (
            <div>
                {extraDot}
                <span>{format(day, "d")}</span>
            </div>
        );
    };

    const getHotelDetailsAndNavigate = (e) => {
        e.preventDefault();
        // console.log({offer});
        let searchParams = {
            GUID: "",
            Locale: loginConfig?.locale?.code,
            HotelId: offer?.hotelId || offer?.id,
            Page: 1,
            currency: loginConfig?.currency?.code,
        };

        let PassengerAgeRoomItemList = [];
        let roomcounter = 1;

        searchfilters.travelers.forEach((element) => {
            for (let i = 0; i < element.infants; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Infant",
                    Room: roomcounter,
                });
            }
            for (let i = 0; i < element.adult; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Adult",
                    Room: roomcounter,
                });
            }
            for (let i = 0; i < element.child; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Child",
                    Room: roomcounter,
                });
            }
            roomcounter++;
        });

        let FlightSegmentList = [];
        if (departureFrom || searchfilters.departureFrom.length) {
            let inbound_params = {};
            let outbound_params = {};

            inbound_params.Departure = {
                CodeList: departureFrom,
                Date: moment(dateRangeState[0]?.startDate).format("YYYY-MM-DD"),
            };

            inbound_params.Arrival = {
                CodeList: !offer?.hotelId && !offer?.hotelImg // Normal Hotel
                    ? [`${offer?.location?.address?.city}, ${offer?.location?.address?.country}`]
                    : [`${offer?.location}, ${offer?.country}`],
            };

            outbound_params.Departure = {
                CodeList: !offer?.hotelId && !offer?.hotelImg // Normal Hotel
                    ? [`${offer?.location?.address?.city}, ${offer?.location?.address?.country}`]
                    : [`${offer?.location}, ${offer?.country}`],
                Date: moment(dateRangeState[0]?.endDate).format("YYYY-MM-DD"),
            };

            // inbound_params.NumberOfStops = searchfilters.numberOfStops;

            outbound_params.Arrival = {
                IataCodeList: departureFrom,
            };
            // outbound_params.NumberOfStops = searchfilters.numberOfStops;

            FlightSegmentList.push(inbound_params);
            FlightSegmentList.push(outbound_params);

        } else {
            return toast.info(T("Toasts:Please_Choose_Departure"));
        }

        if (FlightSegmentList.length > 0) {
            searchParams["FlightSegmentList"] = FlightSegmentList;
        }

        if (PassengerAgeRoomItemList.length > 0) {
            searchParams["PassengerAgeRoomItemList"] = PassengerAgeRoomItemList;
        }

        // console.log({searchParams});
        dispatch(setActivityIndicator(true));
        GetSearchOnlyForHotel(searchParams)
            .then((response) => {
                dispatch(setActivityIndicator(false));
                // console.log({response});

                if (response?.data?.warnings?.length) {
                    toast.warning(response?.data?.warnings[0]?.description);
                }

                let _offers = response?.data?.offers?.map((hotel) => {
                    if (hotel !== undefined) {
                        return {
                            ...hotel?.newPackagedOffer?.hotelOfferSingleRoom,
                            price: {
                                totalPrice: hotel?.price?.packageTotalPrice?.value,
                                currency: hotel?.price?.packageTotalPrice?.currency,
                            },
                            perPersonPrice: {
                                totalPrice: hotel?.price?.averageCostPerPerson?.value,
                                currency: hotel?.price?.averageCostPerPerson?.currency,
                            },
                            address:
                                hotel?.newPackagedOffer?.hotelOfferSingleRoom?.location?.address
                                    ?.city +
                                ", " +
                                hotel?.newPackagedOffer?.hotelOfferSingleRoom?.location?.address
                                    ?.country,
                            objHotel: hotel,
                            guidForBooking: response?.data?.correlationId,
                        };
                    }
                });

                // console.log({_offers});

                dispatch(setSelectedFavoriteHotel(_offers[0]));

                if (_offers?.length) {
                    navigateToDetailPage(
                        _offers[0],
                        dispatch,
                        loginConfig,
                        searchfilters,
                        navigate,
                        "hotel",
                        null,
                        true
                    );
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(setActivityIndicator(false));
                revertValuesToPrevious();
            });
    };

    const revertValuesToPrevious = () => {
        dispatch(setTravelers(hotelPreviousFilters?.travelers));

        if (!offer?.hotelId && !offer?.hotelImg) {
            // Normal Hotel
            const flightData = offer?.objHotel?.newPackagedOffer?.flightOffer; // Selected flight from redux
            const departureSegmentId = flightData?.segmentIds[0];
            const departureFlightSegment = flightData?.segments?.filter(
                f => f.segmentId === departureSegmentId,
            )[0];
            const departureFlight = departureFlightSegment?.legs[0];
            const _departureFrom = departureFlight?.departureAirport?.code;
            // console.log({_departureFrom})
            setLocalDepartureFrom([_departureFrom]);
        } else {
            setLocalDepartureFrom([offer?.departure]);
        }

        setDateRangeState(hotelPreviousFilters?.dateRangeState);
    };

    const getDepartureFromText = () => {
        if (!offer?.hotelId && !offer?.hotelImg) {// Normal Hotel
            if (departureFrom?.length) {
                return departureFrom?.join(', ')
            } else if (searchfilters?.departureFrom?.length) {
                return searchfilters?.departureFrom?.map(a => a.code)?.join(', ')
            }
        } else {
            if (departureFrom?.length) {
                return departureFrom?.join(', ')
            } else {
                return searchfilters?.departureFrom.map(a => a.code)?.join(', ')
            }
        }
    }

    const Calender = () =>
        showCalendar && (
            <div className="calendar-wrapper">
                <DateRangePicker
                    ref={calendarRef}
                    onChange={handleRangeChange}
                    minDate={new Date()}
                    maxDate={new Date(moment().add(332, "days"))}
                    dayContentRenderer={customDayContent}
                    months={2}
                    ranges={dateRangeState}
                    dragSelectionEnabled={false}
                    showMonthAndYearPickers={false}
                    showPreview={false}
                    showDateDisplay={false}
                    weekStartsOn={1}
                    direction={isMobile ? "vertical" : "horizontal"}
                    locale={loginConfig.locale.code === "nl_NL" ? nl : enUS}
                />
                <div className="calendarBtns">
                    <a
                        className="reset-btn"
                        onClick={() => {
                            const focusedDate = dateRangeState[0].startDate;
                            const firstDate = new Date(
                                focusedDate.getFullYear(),
                                focusedDate.getMonth(),
                                1
                            );

                            const pasteDate =
                                focusedDate.getMonth() === new Date().getMonth()
                                    ? new Date()
                                    : firstDate;

                            setDateRangeState([
                                {
                                    startDate: pasteDate,
                                    endDate: pasteDate,
                                    key: "selection",
                                },
                            ]);

                            setIsReset(true);
                        }}
                    >
                        {T("Buttons:Reset")}
                    </a>
                    <a
                        className="reset-btn select"
                        onClick={() => {
                            dispatch(
                                setTravelDate({
                                    startDate: isReset
                                        ? ""
                                        : new Date(dateRangeState[0].startDate).toDateString(),
                                    endDate: isReset
                                        ? ""
                                        : new Date(dateRangeState[0].endDate).toDateString(),
                                })
                            );
                            setShowCalendar(false);
                        }}
                    >
                        {T("Buttons:Select")}
                    </a>
                </div>
            </div>
        );

    return (
        <div className="showmore-favorite">
            <div className="row">
                <div className="col-lg-5">
                    <div className="dates-feilds">
                        <div className="check-in">
                            <div className="icon">
                                <FontAwesomeIcon icon={faCalendar}/>
                            </div>
                            <div
                                className="text"
                                onClick={() => {
                                    setShowCalendar(!showCalendar);
                                    setShowTraveler(false);
                                }}
                            >
                                <h6 className="darkgray-text">{T("Filters:Check_In")}</h6>
                                <p className="no-margin">
                                    {dateRangeState[0]?.startDate
                                        ? moment(dateRangeState[0]?.startDate).format("MMM DD")
                                        : T("Filters:Info_No_Date_Selected")}
                                </p>
                            </div>
                        </div>

                        <div className="check-out">
                            <div className="icon"></div>
                            <div
                                className="text"
                                onClick={() => {
                                    setShowCalendar(!showCalendar);
                                    setShowTraveler(false);
                                }}
                            >
                                <h6 className="darkgray-text">{T("Filters:Check_Out")}</h6>
                                <p className="no-margin">
                                    {dateRangeState[0]?.endDate
                                        ? moment(dateRangeState[0]?.endDate).format("MMM DD")
                                        : T("Filters:Info_No_Date_Selected")}
                                </p>
                            </div>
                        </div>
                        <Dropdown
                            className="calendar-dropdown"
                            show={showCalendar}
                            onToggle={(show) => {
                                setShowTraveler(false);
                                setShowCalendar(show);
                            }}
                        >
                            <Dropdown.Menu className="calender-menu">
                                <Calender/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="destinations">
                        <div className="icon">
                            <FontAwesomeIcon icon={faLocationDot}/>
                        </div>
                        <div className="text">
                            <div
                                onClick={() =>
                                    setModals({
                                        ...modals,
                                        departure: !modals.departure,
                                    })
                                }
                                className="depart-from"
                            >
                                <h6 className="darkgray-text">
                                    {T("Filters:Departure_From")}
                                </h6>
                                <p className="no-margin">{getDepartureFromText()}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="traveler">
                        <Dropdown
                            show={showTraveler}
                            onToggle={(show) => {
                                setShowTraveler(show);
                                setShowCalendar(false);
                            }}
                            align={{sm: "start"} | {md: "end"} | {lg: "end"}}
                        >
                            <Dropdown.Toggle>
                                <div className="traveler-section">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    <div className="text ">
                                        <h6 className="darkgray-text">Travelers</h6>
                                        <p className="no-margin">{`${
                                            searchfilters.travelers?.length || 0
                                        } ${searchfilters.travelers?.length > 1 ? T("Common:Rooms") : T("Common:Room")}, ${getTravelersData(searchfilters).total} ${
                                            getTravelersData(searchfilters).total <= 1
                                                ? T("Common:Traveler")
                                                : T("Common:Travelers").toLowerCase()
                                        }`}</p>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Travelers setShowTraveler={setShowTraveler}/>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-lg-1 text-center">
                    <div
                        className="serach-action green-bg text-center"
                        onClick={getHotelDetailsAndNavigate}
                    >
                        <a className="white-text fs-16">{T("Buttons:Search")}</a>
                    </div>
                </div>
            </div>
            <DepartureFromModal
                show={modals.departure}
                onHide={() => setModals({...modals, departure: false})}
                onApplyButtonHandler={(values) => {
                    if (values?.length > 0) {
                        return setLocalDepartureFrom(
                            values.map((_departure) => _departure.code)
                        );
                    }
                    setLocalDepartureFrom([offer.departure]);
                }}
            />
        </div>
    );
};

FavoriteAccordion.propTypes = {
    offer: PropTypes.object.isRequired,
}

export default FavoriteAccordion;
