import React from "react";
import TabTitle from "./TabsTitle";
import InnerTabs from "./InnerTabs/InnerTabs";
import "./style.scss";
import useTranslationCustom from "../../../i18n/customHook/useTranslation";

const BookingTabContent = () => {
  const { T } = useTranslationCustom();

  return (
    <>
      <TabTitle title={T("Profile:Bookings")}></TabTitle>
      <div className="tabs-inner-content bookings-tab">
        <InnerTabs />
      </div>
    </>
  );
};

export default BookingTabContent;
