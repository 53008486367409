import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch} from "react-redux";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";
import "./style.scss";
import {ResetPasswordService} from "../../services/ResetPasswordService";
import {toast} from "react-toastify";
import {_EMAIL_REG_EXP} from "../../helpers/common";
import PropTypes from "prop-types";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const ForgotPasswordModal = (props) => {
    const {T} = useTranslationCustom();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (!props.show) {
            setEmail("");
        }
    }, [props.show]);

    const validateForm = (e) => {
        e.preventDefault();
        if (email.length === 0) {
            toast(T("Toasts:Empty_Message_Field"));
        } else if (_EMAIL_REG_EXP.test(email) === false) {
            toast(T("Toasts:Invalid_Email_Message"));
        } else {
            resetPassword();
        }
    };

    const resetPassword = () => {
        dispatch(setActivityIndicator(true));
        ResetPasswordService(email)
            .then((_response) => {
                dispatch(setActivityIndicator(false));
                toast(_response?.message);
                props.onHide();
            })
            .catch(() => {
                dispatch(setActivityIndicator(false));
            });
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="fs-38 medium">{T("Home:Home_Forget_Password_Header")}</h4>
                <form className="auth-form login">
                    <div className="form-group">
                        <input
                            value={email}
                            type="email"
                            placeholder={T("Home:Home_Login_Modal_Email_Placeholder")}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-btn">
                        <button onClick={validateForm}>
                            {T("Buttons:Send_Email")}
                        </button>
                    </div>
                    <p className="fs-12 font-noto" style={{marginTop: "30px"}}>
                        {T("ForgotPassword.Description")}
                    </p>
                </form>
            </Modal.Body>
        </Modal>
    );
};

ForgotPasswordModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default ForgotPasswordModal;
