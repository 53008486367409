import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalImg from "../../assets/images/modal-img.png";
import "./style.scss";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const SuccessModal = (props) => {
  const { T } = useTranslationCustom();
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="success-content">
            <h4 className="fs-36 medium">{T("Home:Check_Your_Email")}</h4>
            <img src={ModalImg}  alt={'modal-image'}/>
            <p className="fs-18 m-0 medium">
              {T("Home:Home_Signup_Modal_Verification_Email")}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessModal;
